<template>
  <div class="px-8">
    <div class="flex justify-between items-center">
      <div class="flex items-center my-4">
        <span class="text-lg font-bold text-primary-heavy">濃度</span>
        <label class="switch mx-2">
          <input
            type="checkbox"
            v-model="switch_editing_type"
            :true-value="'AQI'"
            :false-value="'concentration'"
            class="peer sr-only"
          >
          <div class="slider w-11"></div>
        </label>
        <span class="text-lg font-bold text-primary-heavy">AQI</span>
      </div>
      <button
        class="btn_primary--line"
        v-show="sp_dtime_fcst_edited_data_exist"
        @click="load_fcst_edited_data"
      >
      <span>
        {{ temp_release }}
      </span>
      </button>
      <button
        class="btn_primary--line"
        @click="save_editing_data"
      >
        Save
      </button>
    </div>

    <!-- table -->
    <table class="border mb-4">
      <tr class="text-primary-heavy border">
        <th class="border">
          <div class="flex justify-around">
            <label>
              <input
                type="checkbox"
                v-model="select_all_area"
                class="peer sr-only"
              />
              <div class="aqfe_checkbox m-0">
              </div>
            </label>
            <label>
              <input
                type="checkbox"
                v-model="select_all_pollutant"
                class="peer sr-only"
              />
              <div class="aqfe_checkbox-heavy m-0">
              </div>
            </label>
          </div>
        </th>
        <th>
          <label class="flex items-center justify-center gap-2 py-4">
            <input
              type="checkbox"
              v-model="selected_pollutant_list"
              value="PM2.5"
              class="peer sr-only"
            />
            <div class="aqfe_checkbox-heavy">
            </div>
            <span class="text-left">
              <div class="text-sm">
                PM<sub>2.5</sub>
              </div>
              <div class="whitespace-nowrap text-xs">
                (µg/m<sub>3</sub>)
              </div>
            </span>
          </label>
        </th>
        <th>
          <label class="flex items-center justify-center gap-2 py-4">
            <input
              type="checkbox"
              v-model="selected_pollutant_list"
              value="O3_8hr"
              class="peer sr-only"
            />
            <div class="aqfe_checkbox-heavy">
            </div>
            <span class="text-left">
              <div class="text-sm">
                O<sub>3_8hr</sub>
              </div>
              <div class="whitespace-nowrap text-xs">
                (ppb)
              </div>
            </span>
          </label>
        </th>
        <th>
          <label class="flex items-center justify-center gap-2 py-4">
            <input
              type="checkbox"
              v-model="selected_pollutant_list"
              value="O3"
              class="peer sr-only"
            />
            <div class="aqfe_checkbox-heavy">
            </div>
            <span class="text-left">
              <div class="text-sm">
                O<sub>3</sub>
              </div>
              <div class="whitespace-nowrap text-xs">
                (ppb)
              </div>
            </span>
          </label>
        </th>
        <th>
          <label class="flex items-center justify-center gap-2 py-4">
            <input
              type="checkbox"
              v-model="selected_pollutant_list"
              value="PM10"
              class="peer sr-only"
            />
            <div class="aqfe_checkbox-heavy">
            </div>
            <span class="text-left">
              <div class="text-sm">
                PM<sub>10</sub>
              </div>
              <div class="whitespace-nowrap text-xs">
                (µg/m<sub>3</sub>)
              </div>
            </span>
          </label>
        </th>
        <th>
          <label class="flex items-center justify-center gap-2 py-4">
            <input
              type="checkbox"
              v-model="selected_pollutant_list"
              value="SO2"
              class="peer sr-only"
            />
            <div class="aqfe_checkbox-heavy">
            </div>
            <span class="text-left">
              <div class="text-sm">
                SO<sub>2</sub>
              </div>
              <div class="whitespace-nowrap text-xs">
                (ppb)
              </div>
            </span>
          </label>
        </th>
        <th>
          <label class="flex items-center justify-center gap-2 py-4">
            <input
              type="checkbox"
              v-model="selected_pollutant_list"
              value="NO2"
              class="peer sr-only"
            />
            <div class="aqfe_checkbox-heavy">
            </div>
            <span class="text-left">
              <div class="text-sm">
                NO<sub>2</sub>
              </div>
              <div class="whitespace-nowrap text-xs">
                (ppb)
              </div>
            </span>
          </label>
        </th>
        <th class="border">
          <div class="flex justify-around">
            <div>
              主要AQI
            </div>
            <div>
              次要AQI
            </div>
          </div>
        </th>
      </tr>

      <tr
        v-for="(area, idx) in area_list"
        :key="'area_' + idx"
      >
        <td class="border-l border-r">
          <label class="flex items-center mx-4 gap-2">
            <input
              type="checkbox"
              v-model="selected_area_list"
              :value="area"
              class="peer sr-only"
            />
            <div class="aqfe_checkbox">
            </div>
            <span class="text-sm font-bold text-primary-heavy whitespace-nowrap">
              {{ area }}
            </span>
          </label>
        </td>
        <td
          v-for="(pollutant, idx) in pollutant_list"
          :key="'pollutant_' +   idx"
          class="first:border-l last:border-r"
        >
          <ValueInput
            :area="area"
            :pollutant="pollutant"
            :compute_aqi_type="null"
          />
        </td>
        <td class="border-l border-r">
          <div class="flex justify-around">
            <ValueInput
              :area="area"
              :compute_aqi_type="'Primary_AQI'"
            />
            <ValueInput
              :area="area"
              :compute_aqi_type="'Secondary_AQI'"
            />
          </div>
        </td>
      </tr>
    </table>
    <div
     class="flex justify-between my-4"
    >
      <button 
        class="ml-6 w-6/13" 
        @click="undo"
        :disabled="!undo_allowed"
        :class="{'btn_disabled' : !undo_allowed, 'btn_primary--line': undo_allowed}"
        >
        <i class="icon-arrow-back text-lg block"
        >
          <span class="font-bold">復原</span>
        </i>
      </button>
      <button
        class="mr-6 w-6/13" 
        @click="redo"
        :disabled="!redo_allowed"
        :class="{'btn_disabled' : !redo_allowed, 'btn_primary--line': redo_allowed}"
        >
        <i class="icon-arrow-forward text-lg block"
        >
          <span class="font-bold">重做</span>
        </i>
      </button>
    </div>
  </div>
</template>

<script>
import ValueInput from '@/components/ValueInput'
import { MainVState } from '@/state/main'
import { isObject, deepEqual, arraysEqual } from "@/libs/utils"

export default {
  name: 'Editor',

  components: {
    ValueInput
  },

  data() {
    return {
      loading: {
        'obs': false,
        'fcst_edited': false,
        'fcst_published': false,
        'fcst_multi_models': false
      },
      select_all_area: false,
      select_all_pollutant: false,
      type: 'AQI',
      area_list: MainVState.area_list,
      pollutant_list: MainVState.pollutant_list
    }
  },

  computed: {
    editing_pollutants_data() {
      return MainVState.editing_pollutants_data
    },

    editing_aqi_data() {
      return MainVState.editing_aqi_data
    },

    fcst_datetime_selected() {
      return MainVState.fcst_datetime_selected
    },

    fcst_datetime_selected_iso() {
      return MainVState.fcst_datetime_selected.isostr()
    },

    fcst_edited_data() {
      return MainVState.fcst_edited_data
    },

    obs_data() {
      return MainVState.obs_data
    },

    sp_dtime_fcst_edited_data_exist() {
      // check if temporary data exist
      return Object.keys(MainVState.fcst_edited_data).length > 0
    },

    selected_pollutant_list: {
      get() {
        return MainVState.selected_pollutant_list
      },
      set(val) {
        MainVState.set_selected_pollutant_list(val)
      }
    },

    selected_area_list: {
      get() {
        return MainVState.selected_area_list
      },

      set(val) {
        MainVState.set_selected_area_list(val)
      }
    },

    switch_editing_type: {
      get() {
        return MainVState.editing_type
      },

      set(val) {
        MainVState.set_editing_type(val)
      }
    },

    temp_release() {
      if (Object.entries(this.fcst_edited_data).length) {
        let release_datetime = this.fcst_edited_data[0]['init_time']
        return release_datetime.toLST().strftime('%m%d%H%M')
      }
      return null
    },

    operation_steps() {
      return MainVState.operation_steps
    },

    current_idx() {
      return MainVState.current_idx
    },

    undo_allowed() {
      if (this.current_idx === 1) {
        return false
      }
      return true
    },

    redo_allowed() {
      if (this.current_idx === this.operation_steps.length) {
        return false
      }
      return true
    }
  },

  watch: {
    select_all_area(val) {
      if (val) {
        MainVState.set_selected_area_list(this.area_list)
      } else {
        MainVState.set_selected_area_list([])
      }
    },

    select_all_pollutant(val) {
      if (val) {
        MainVState.set_selected_pollutant_list(this.pollutant_list)
      } else {
        MainVState.set_selected_pollutant_list([])
      }
    },

    selected_area_list(val) {
      if (!val.length) {
        this.select_all_area = false
      } else if (val.length === this.area_list.length) {
        this.select_all_area = true
      }
    },

    selected_pollutant_list(val) {
      if (!val.length) {
        this.select_all_pollutant = false
      } else if (val.length === this.pollutant_list.length) {
        this.select_all_pollutant = true
      }
    },

    editing_pollutants_data: {
      handler(val) {
        if (this.current_idx === 0) {
          // 初始載入畫面
          MainVState.set_operation_steps(val)
        }
        else {
          /*
          這邊分成兩種情況：
          1. watch到的污染物數值和目前的operation_step一樣的話，
             是使用者按下「復原」或「重做」按鈕，直接回傳不做任何處理。
          2. watch到的污染物數值和目前的operation_step不一樣的話，
             代表使用者更新了表格資料，需要移除current_idx位置後續所紀錄到的steps，
             並且去set新的operation_step。
          */
          let undo_or_redo_clicked = arraysEqual(val, this.operation_steps[this.current_idx-1]['editing_pollutants_data'])
          if (undo_or_redo_clicked) {
            return
          }
          MainVState.remove_operation_steps(this.current_idx)
          MainVState.set_operation_steps(val)
        }
      },
      deep: true,
      immediate: true
    }
  },

  methods: {
    is_loading(data_type) {
      return this.is_loading[data_type]
    },

    load_fcst_edited_data() {
      MainVState.set_editing_pollutant_data_by_fcsttime()
    },

    save_editing_data() {
      MainVState.save_editing_data()
    },

    undo() {
      MainVState.reduce_current_idx()
      let selected_operation_step = Object.deepAssign({}, this.operation_steps[this.current_idx-1])
      MainVState.set_selected_operation_step(selected_operation_step)
    },

    redo() {
      MainVState.add_current_idx()
      let selected_operation_step = Object.deepAssign({}, this.operation_steps[this.current_idx-1])
      MainVState.set_selected_operation_step(selected_operation_step)
    }
  },

  mounted() {
  }
}
</script>


<style lang="scss" scoped>
.switch {
  @apply relative w-12 h-6;
  @apply border border-primary rounded-full;
}

.slider {
  @apply absolute inset-0 bg-white rounded-full cursor-pointer transition;

  &:before {
    content: "";
    @apply absolute w-4 h-4 left-[2px] top-[3px];
    @apply bg-primary-heavy rounded-full transition;
    @apply peer-checked:translate-x-[26px];
  }
}

.aqfe_checkbox {
  @apply checkbox_base;
  @apply peer-checked:bg-primary m-0;
}
.aqfe_checkbox-heavy {
  @apply checkbox_base;
  @apply peer-checked:bg-primary-heavy m-0;
}
</style>
