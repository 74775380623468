const isObject = function(object){
  return object != null && typeof object === 'object'
}

const deepEqual = function(object1, object2) {
  const keys1 = Object.keys(object1);
  const keys2 = Object.keys(object2);
  if (keys1.length !== keys2.length) {
    return false;
  }
  for (const key of keys1) {
    const val1 = object1[key];
    const val2 = object2[key];
    const areObjects = isObject(val1) && isObject(val2);
    if (
      areObjects && !deepEqual(val1, val2) ||
      !areObjects && val1 !== val2
    ) {
      return false
    }
  }
  return true
}

const arraysEqual = function(a1, a2) {
  return a1.length === a2.length && a1.every((o, idx) => deepEqual(o, a2[idx]))
}

export { isObject, deepEqual, arraysEqual }
