<template>
  <div class="relative">
    <select
      class="input--base appearance-none pr-8 cursor-pointer w-full"
      v-model="_value"
    >
      <slot>
        <option value="0">請選擇...</option>
        <option value="1">option1</option>
        <option value="2">option2</option>
      </slot>
    </select>
    <i class="icon-arrow_down vertical_center right-3 pointer-events-none"></i>
  </div>
</template>

<script>
export default {
  props: {
    value: {
      required: true
    },

    options: {
      type: Array,
      default: () => []
    }
  },

  computed: {
    _value: {
      get() {
        return this.value
      },
      set(val) {
        this.$emit('input', val)
      }
    }
  }
}
</script>

<style>
@import "./base_font_icon/style.css";
</style>
