<template>
  <div class='relative flex items-center px-2 border-b border-gray-200'>
    <div
      class="text-primary-heavy text-xl font-bold mx-6"
    >
      發布時間 {{ release_time }}
    </div>

    <div class="flex">
      <label
        v-for="datetime_shift in fcst_datetime_shifts"
        :key="datetime_shift"
        class="my-4 mx-2"
      >
        <input
          type="radio"
          v-model="datetime_selected_shift"
          :value="datetime_shift"
          class="peer sr-only"
        >
        <div class="radio_btn btn_primary--line">
          <span>{{ fcst_datetime(datetime_shift) }}</span>
        </div>
      </label>
    </div>

    <button
      class="absolute right-0 vertical_center mx-6 btn_primary--line"
      @click="logout"
    >
      登出
    </button>
  </div>
</template>
<script>
import { MainVState } from '@/state/main'
import { UserVState } from '@/libs/cb-account/vstate'

export default {
  name: 'Navigator',

  data() {
    return {
      datetime_selected_shift: 0
    }
  },

  computed: {
    release_time() {
      return MainVState.release_time.toLST().strftime("%H:%M")
    },
    fcst_datetime_shifts() {
      return [...Array(MainVState.fcst_datetime_list.length).keys()]
    }
  },

  methods: {
    fcst_datetime(days) {
      let fcst_datetime = MainVState.fcst_datetime_list[days]
      return fcst_datetime.strftime("%m/%d(%-a)")
    },

    datetime_changed(datetime_selected_shift) {
      this.datetime_selected_shift = datetime_selected_shift
      MainVState.set_fcst_datetime_selected(datetime_selected_shift)
    },

    logout() {
      UserVState.logout()
      .then(() => {
        location.reload()
      })
      .catch(code => {
        alert('無法登出')
      })
    }
  },

  watch: {
    datetime_selected_shift(val) {
      this.datetime_changed(val)
    }
  },

  mounted() {
  }
}
</script>
