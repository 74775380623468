const MODEL_COLOR = {
  'OP10': 'rgb(219, 85, 72)',
  'OP10-DCA': 'rgb(231, 162, 158)',
  'OP10-DMOS': 'rgb(241, 209, 249)',
  'OP20': 'rgb(74, 116, 172)',
  'OP20-DCA': 'rgb(93, 153, 240)',
  'OP20-DMOS': 'rgb(169, 203, 246)',
  'AQF4': 'rgb(59, 98, 37)',
  'AQF4-DCA': 'rgb(128, 197, 94)',
  'AQF4-DMOS': 'rgb(194, 249, 172)',
  'MOS-DCA': 'rgb(79, 49, 236)',
  'BMA': 'rgb(240, 195, 92)',
  'RCEC': 'rgb(240, 150, 100)',
  'obs': 'rgb(112, 112, 112)',
}

const MODEL_RANK_COLOR = [
  'rgb(126, 56, 186)',
  'rgb(230, 0, 0)',
  'rgb(255, 152, 53)',
]

export {
  MODEL_COLOR,
  MODEL_RANK_COLOR
}