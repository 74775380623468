<template>
  <div class="m-1">
    <div v-if="compute_aqi_type" class="relative">
      <input
        type="number"
        v-model="primary_secondary_aqi"
        @input="validate_value($event, 'primary_secondary_aqi')"
        :style="`border-top-width: 20px; border-top-color: ${color_mapping(primary_secondary_aqi)};`"
        class="pollutant_input"
      />
      <!--顯示主要/次要AQI所對應的污染物-->
      <div
        class="absolute top-1 horizontal_center text-xs font-bold text-white"
        :class="{'text-gray-800': [0, 2].includes(get_severity_level(primary_secondary_aqi))}"
      >
        {{ primary_secondary_aqi_pollutant }}
      </div>
    </div>
    <div v-else>
      <input
        type="number"
        :value="pollutant_value"
        @change="pollutant_value = $event.target.value"
        @input="validate_value($event, 'pollutant_value')"
        :style="`border-top-color: ${color_mapping(pollutant_aqi_value)};`"
        class="pollutant_input"
      />
    </div>
  </div>
</template>
<script>

import { MainVState } from '@/state/main'

export default {
  name: 'ValueInput',

  props: {
    area: {
      type: String,
      default: null
    },
    pollutant: {
      type: String,
      default: null
    },
    compute_aqi_type: {
      type: String,
      default: null
    },
  },

  data() {
    return {
    }
  },

  methods: {
    get_severity_level(AQI) {
      return MainVState.get_severity_level(AQI)
    },

    color_mapping(AQI) {
      return MainVState.get_color_mapping(AQI)
    },

    validate_value(event, item) {
      // Todo: 目前可以輸入 1e2, 003
      let value = Number(event.target.value)
      if (value < 0 || Number.isNaN(value)) {
        this.$set(this, item, 0)
      }
    },

    aqi_to_concentration(pollutant, aqi_value) {
      // AQI對照表參考：https://airtw.moenv.gov.tw/cht/Information/Standard/AirQualityIndicator.aspx
      let result = 0
      if (pollutant == 'PM10') {
        if (0 <= aqi_value && aqi_value <= 50) {
          result = aqi_value * (50 - 0) / (50 - 0)
        }
        else if (51 <= aqi_value && aqi_value <= 100) {
          result = (aqi_value - 51) * (100 - 51) / (100 - 51) + 51
        }
        else if (101 <= aqi_value && aqi_value <= 150) {
          result = (aqi_value - 101) * (150 - 101) / (254 - 101) + 101
        }
        else if (151 <= aqi_value && aqi_value <= 200) {
          result = (aqi_value - 151) * (200 - 151) / (254 - 101) + 255
        }
        else if (201 <= aqi_value && aqi_value <= 300) {
          result = (aqi_value - 201) * (300 - 201) / (424 - 355) + 355
        }
        else if (301 <= aqi_value && aqi_value <= 400) {
          result = (aqi_value - 301) * (400 - 301) / (504 - 425) + 425
        }
        else if (401 <= aqi_value) {
          result = (aqi_value - 401) * (500 - 401) / (604 - 505) + 505
        }
      }
      else if (pollutant == 'O3_8hr') {
        if (0 <= aqi_value && aqi_value <= 50) {
          result = (aqi_value / 50) * 54
        }
        else if (51 <= aqi_value && aqi_value <= 100) {
          result = (aqi_value - 51) / (100 - 51) * (70 - 55) + 55
        }
        else if (101 <= aqi_value && aqi_value <= 150) {
          result = (aqi_value - 101) / (150 - 101) * (85 - 71) + 71
        }
        else if (151 <= aqi_value && aqi_value <= 200) {
          result = (aqi_value - 151) / (200 - 151) * (105 - 86) + 86
        }
        else if (201 <= aqi_value && aqi_value <= 300) {
          result = (aqi_value - 201) / (300 - 201) * (200 - 106) + 106
        }
      }
      else if (pollutant == 'SO2') {
        if (0 <= aqi_value && aqi_value <= 50) {
          result = (aqi_value / 50) * 20
        }
        else if (51 <= aqi_value && aqi_value <= 100) {
          result = (aqi_value - 51) / (100 - 51) * (75 - 21) + 21
        }
        else if (101 <= aqi_value && aqi_value <= 150) {
          result = (aqi_value - 101) / (150 - 101) * (185 - 76) + 76
        }
        else if (151 <= aqi_value && aqi_value <= 200) {
          result = (aqi_value - 151) / (200 - 151) * (304 - 186) + 186
        }
        else if (201 <= aqi_value && aqi_value <= 300) {
          result = (aqi_value - 201) / (300 - 201) * (604 - 305) + 305
        }
        else if (301 <= aqi_value && aqi_value <= 400) {
          result = (aqi_value - 201) / (300 - 201) * (804 - 605) + 605
        }
        else if (401 <= aqi_value && aqi_value <= 500) {
          result = (aqi_value - 201) / (300 - 201) * (1004 - 805) + 805
        }
      }
      else if (pollutant == 'NO2') {
        if (0 <= aqi_value && aqi_value <= 50) {
          result = (aqi_value / 50) * 30
        }
        else if (51 <= aqi_value && aqi_value <= 100) {
          result = (aqi_value - 51) / (100 - 51) * (100 - 31) + 31
        }
        else if (101 <= aqi_value && aqi_value <= 150) {
          result = (aqi_value - 101) / (150 - 101) * (360 - 101) + 101
        }
        else if (151 <= aqi_value && aqi_value <= 200) {
          result = (aqi_value - 151) / (200 - 151) * (649 - 361) + 361
        }
        else if (201 <= aqi_value && aqi_value <= 300) {
          result = (aqi_value - 201) / (300 - 201) * (1249 - 650) + 650
        }
        else if (301 <= aqi_value && aqi_value <= 400) {
          result = (aqi_value - 201) / (300 - 201) * (1649 - 1250) + 1250
        }
        else if (401 <= aqi_value && aqi_value <= 500) {
          result = (aqi_value - 201) / (300 - 201) * (2049 - 1650) + 1650
        }

      }
      else if (pollutant == 'O3') {
        if (101 <= aqi_value && aqi_value <= 150) {
          result = (aqi_value - 101) / (150 - 101) * (164 - 125) + 125
        }
        else if (151 <= aqi_value && aqi_value <= 200) {
          result = (aqi_value - 151) / (200 - 151) * (204 - 165) + 165
        }
        else if (201 <= aqi_value && aqi_value <= 300) {
          result = (aqi_value - 201) / (300 - 201) * (404 - 205) + 205
        }
        else if (301 <= aqi_value && aqi_value <= 400) {
          result = (aqi_value - 201) / (300 - 201) * (504 - 405) + 405
        }
        else if (401 <= aqi_value && aqi_value <= 500) {
          result = (aqi_value - 201) / (300 - 201) * (604 - 505) + 505
        }

      }
      else if (pollutant == 'PM2.5') {
        if (0 <= aqi_value && aqi_value <= 50) {
          result = (aqi_value / 50) * 15.4
        }
        else if (51 <= aqi_value && aqi_value <= 100) {
          result = (aqi_value - 51) / (100 - 51) * (35.4 - 15.5) + 15.5
        }
        else if (101 <= aqi_value && aqi_value <= 150) {
          result = (aqi_value - 101) / (150 - 101) * (54.4 - 35.5) + 35.5
        }
        else if (151 <= aqi_value && aqi_value <= 200) {
          result = (aqi_value - 151) / (200 - 151) * (150.4 - 54.5) + 54.5
        }
        else if (201 <= aqi_value && aqi_value <= 300) {
          result = (aqi_value - 201) / (300 - 201) * (250.4 - 150.5) + 150.5
        }
        else if (301 <= aqi_value && aqi_value <= 400) {
          result = (aqi_value - 201) / (300 - 201) * (350.4 - 250.5) + 250.5
        }
        else if (401 <= aqi_value && aqi_value <= 500) {
          result = (aqi_value - 201) / (300 - 201) * (500.4 - 350.5) + 350.5
        }
      }
      return Math.floor(result)
    },

    concentration_to_aqi(pollutant, value) {
      // AQI對照表參考：https://airtw.moenv.gov.tw/cht/Information/Standard/AirQualityIndicator.aspx
      let result = 0
      if (pollutant == 'PM10') {
        if (0 <= value && value <= 50) {
          result = value * (50 - 0) / (50 - 0)
        }
        else if (51 <= value && value <= 100) {
          result = (value - 51) * (100 - 51) / (100 - 51) + 51
        }
        else if (101 <= value && value <= 254) {
          result = (value - 101) * (150 - 101) / (254 - 101) + 101
        }
        else if (255 <= value && value <= 354) {
          result = (value - 255) * (200 - 151) / (354 - 255) + 151
        }
        else if (355 <= value && value <= 424) {
          result = (value - 355) * (300 - 201) / (424 - 355) + 201
        }
        else if (425 <= value && value <= 504) {
          result = (value - 425) * (400 - 301) / (504 - 425) + 301
        }
        else if (505 <= value && value <= 604) {
          result = (value - 505) * (500 - 401) / (604 - 505) + 401
        }
        else if (605 <= value) {
          result = 501
        }
      }
      else if (pollutant == 'PM2.5') {
        if (0 <= value && value <= 15.4) {
          result = (value / 15.4) * 50
        }
        else if (15.5 <= value && value <= 35.4) {
          result = (value - 15.5) / (35.4 - 15.5) * (100 - 51) + 51
        }
        else if (35.5 <= value && value <= 54.4) {
          result = (value - 35.5) / (54.4 - 35.5) * (150 - 101) + 101
        }
        else if (54.5 <= value && value <= 150.4) {
          result = (value - 54.5) / (150.4 - 54.5) * (200 - 151) + 151
        }
        else if (150.5 <= value && value <= 250.4) {
          result = (value - 150.5) / (250.4 - 150.5) * (300 - 201) + 201
        }
        else if (250.5 <= value && value <= 350.4) {
          result = (value - 250.5) / (350.4 - 250.5) * (400 - 301) + 301
        }
        else if (350.5 <= value && value <= 500.4) {
          result = (value - 350.5) / (500.4 - 350.5) * (500 - 401) + 401
        }
        else if (500.5 <= value) {
          result = 501
        }
      }
      else if (pollutant == 'O3_8hr') {
        if (0 <= value && value <= 54) {
          result = (value / 54) * 50
        }
        else if (55 <= value && value <= 70) {
          result = (value - 55) / (70 - 55) * (100 - 51) + 51
        }
        else if (71 <= value && value <= 85) {
          result = (value - 71) / (85 - 71) * (150 - 101) + 101
        }
        else if (86 <= value && value <= 105) {
          result = (value - 86) / (105 - 86) * (200 - 151) + 151
        }
        else if (106 <= value && value <= 200) {
          result = (value - 106) / (200 - 106) * (300 - 201) + 201
        }
        else if (201 <= value) {
          result = 301
        }
      }
      else if (pollutant == 'O3'){
        if (125 <= value && value <= 164) {
          result = (value - 125) / (164 - 125) * (150 - 101) + 101
        }
        else if (165 <= value && value <= 204) {
          result = (value - 165) / (204 - 165) * (200 - 151) + 151
        }
        else if (205 <= value && value <= 404) {
          result = (value - 205) / (404 - 205) * (300 - 201) + 201
        }
        else if (405 <= value && value <= 504) {
          result = (value - 405) / (504 - 405) * (400 - 301) + 301
        }
        else if (505 <= value && value <= 604) {
          result = (value - 505) / (604 - 505) * (500 - 401) + 401
        }
        else if (605 <= value) {
          result = 501
        }
      }
      else if (pollutant == 'SO2') {
        if (0 <= value && value <= 20) {
          result = (value / 20) * 50
        }
        else if (21 <= value && value <= 75) {
          result = (value - 21) / (75 - 21) * (100 - 51) + 51
        }
        else if (76 <= value && value <= 185) {
          result = (value - 76) / (185 - 76) * (150 - 101) + 101
        }
        else if (186 <= value && value <= 304) {
          result = (value - 186) / (304 - 186) * (200 - 151) + 151
        }
        else if (305 <= value && value <= 604) {
          result = (value - 305) / (604 - 305) * (300 - 201) + 201
        }
        else if (605 <= value && value <= 804) {
          result = (value - 605) / (804 - 605) * (400 - 301) + 301
        }
        else if (805 <= value && value <= 1004) {
          result = (value - 805) / (1004 - 805) * (500 - 401) + 401
        }
        else if (1005 <= value) {
          result = 501
        }
      }
      else if (pollutant == 'NO2') {
        if (0 <= value && value <= 30) {
          result = (value / 30) * 50
        }
        else if (31 <= value && value <= 100) {
          result = (value - 31) / (100 - 31) * (100 - 51) + 51
        }
        else if (101 <= value && value <= 360) {
          result = (value - 101) / (360 - 101) * (150 - 101) + 101
        }
        else if (361 <= value && value <= 649) {
          result = (value - 361) / (649 - 361) * (200 - 151) + 151
        }
        else if (650 <= value && value <= 1249) {
          result = (value - 650) / (1249 - 650) * (300 - 201) + 201
        }
        else if (1250 <= value && value <= 1649) {
          result = (value - 1250) / (1649 - 1250) * (400 - 301) + 301
        }
        else if (1650 <= value && value <= 2049) {
          result = (value - 1650) / (2049 - 1650) * (500 - 401) + 401
        }
        else if (2050 <= value) {
          result = 501
        }
      }

      if (result % 5 != 0) {
        return result + (5 - result % 5)
      }
      else {
        return result
      }
    }

  },

  computed: {
    editing_type() {
      return MainVState.editing_type
    },

    editing_aqi_data() {
      return MainVState.get_editing_aqi_data(this.area, this.compute_aqi_type)
    },

    primary_secondary_aqi: {
      get() {
        // 調整污染物數據時，在 MainVState 會自動重算 primary/secondary AOI
        // 直接 get 取值來更新
        return this.editing_aqi_data.value
      },

      set(val) {
        // 手動直接調整 primary/secondary AOI 時，將數據傳回 MainVState，地圖才會變色
        MainVState.set_editing_aqi_data(this.area, this.compute_aqi_type, val, this.pollutant)
      }
    },

    primary_secondary_aqi_pollutant() {
      // primary/secondary AQI對應的污染物，當不同污染物AQI相同時依PM2.5、O3-8hr、O3、PM10、SO2、NO2排序
      return this.editing_aqi_data.pollutant
    },

    pollutant_aqi_value() {
      return MainVState.get_editing_pollutant_data(this.area, this.pollutant, 'AQI')
    },

    pollutant_value: {
      get() {
        return MainVState.get_editing_pollutant_data(this.area, this.pollutant)
      },

      set(val) {
        if (this.editing_type === 'AQI') {
          MainVState.set_editing_pollutant_data(
              this.area, this.pollutant, 'AQI', val)
          MainVState.set_editing_pollutant_data(
              this.area, this.pollutant, 'concentration', this.aqi_to_concentration(this.pollutant, val))
        }
        else {
          MainVState.set_editing_pollutant_data(
              this.area, this.pollutant, 'concentration', val)
          MainVState.set_editing_pollutant_data(
              this.area, this.pollutant, 'AQI', this.concentration_to_aqi(this.pollutant, val))
        }
      }
    }
  },

  watch: {
  },

  mounted() {

  },

  created() {

  }
}
</script>

<style lang="scss" scoped>
.pollutant_input {
  @apply rounded-lg border-none outline-none;
  @apply w-[86px] h-[60px] text-lg text-center bg-gray-50 shadow-inner;
  @apply border-solid border-t-8 border-primary;
}

// 隱藏 number input 右邊的上下箭頭
/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}
</style>
