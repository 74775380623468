import {req as aqfe_request} from "@/axios/aqfe"

export const ObsRepo = {
    // Todo: complete the code according backend url
    // example:
    // list: {
    //   get: () => aqfe_request("get", 'frims/flooding_history/list/')
    // },
    // geojson: {
    //   get: (params) => aqfe_request("get", "frims/flooding_history/geojson/", params)
    // },
    get: () => aqfe_request("get", 'obs/')

}