import Vue from 'vue'
import VueRouter from 'vue-router'
import Main from '@/views/Main.vue'
import Login from '@/views/Login'
import { UserVState } from '@/libs/cb-account/vstate'

Vue.use(VueRouter)

const routes = [
  {
    path: '/login',
    name: 'Login',
    component: Login,
  },
  {
    path: '/',
    name: 'Home',
    component: Main,
    meta: {
      requireLogin: true
    }
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

router.beforeEach((to, from, next) => {
  if (to.matched.some(res => res.meta.requireLogin)) {
    UserVState.verify()
    .then(() => {
      next()
    })
    .catch(() => {
      next({
        name: 'Login',
        query: {
          redirect: to.fullPath
        }
      })
    })
  } else {
    next()
  }
})

export default router
