<template>
  <CBAccountLogin
    :homepage_path="homepage_path"
  >
    <template v-slot:logo>
      <img src="@/assets/imgs/epa_logo.png" alt="epa_logo" class=" w-40 h-40 m-4">
      <h1 class=" text-primary text-5xl font-bold whitespace-nowrap m-4">空氣品質預報編輯系統</h1>
    </template>
  </CBAccountLogin>
</template>

<script>
import CBAccountLogin from '@/libs/cb-account/Login'

export default {
  components: {
    CBAccountLogin
  },

  computed: {
    homepage_path() {
      let _path = {}
      _path.name = 'FcstExp'
      return _path
    },
  }
}
</script>

<style lang="scss" scoped>
</style>
