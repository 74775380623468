/* eslint-disable */
import { req } from './account_axios'

// TODO: 改為新架構(not finished)
import axios from 'axios'

const account_axios = axios.create({
  baseURL: process.env.VUE_APP_SERVER_HOST
})

account_axios.interceptors.response.use(res => {
  return res
})
account_axios.defaults.headers.get['Accept'] = 'application/json'
account_axios.defaults.headers.post['Content-Type'] = 'application/json'
account_axios.defaults.withCredentials = true
account_axios.defaults.xsrfCookieName = 'csrftoken'
account_axios.defaults.xsrfHeaderName = 'X-CSRFToken'
account_axios.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest'


export const AccountRepository = {
  get_url(name) {
    if (name === "get_profile") { return "user/profile/" }
    if (name === "update_info") { return "user/profile/modify/" }
    if (name === "change_pwd") { return "user/password/change/" }
    if (name === "reset_pwd") {return "user/password/reset/" }
    if (name === "register") {return "user/create/" }
    if (name === "send_verification") { return "user/send_verification/" }
    if (name === "verify_verification") { return "user/verify_verification/"}
  },

  login: (data) => req('post', "user/login/", data),
  logout: () => req('get', "user/logout/"),
  verify: (params) => req('get', "user/verify/", params),
  user_permission: {
    get: () => req('get', "auth_mgr/user/permission/")
  },


  get_profile() {
    let _url = this.get_url("get_profile")
    return new Promise((resolve, reject) => {
      account_axios
        .get(_url)
        .then(response => {
          resolve({
            data: response.data.data,
            message: response.data.message,
            code: response.status
          })
        })
        .catch(error => {
          console.error(error)
          reject({
            data: error.response.data.data,
            message: error.response.data.message,
            code: error.response.status
          })
        })
    })
  },

  change_pwd(data) {
    let _url = this.get_url("change_pwd")
    return new Promise((resolve, reject) => {
      account_axios
        .post(_url, data)
        .then(response => {
          resolve({
            data: response.data.data,
            message: response.data.message,
            code: response.status
          })
        })
        .catch(error => {
          console.error(error)
          reject({
            data: error.response.data.data,
            message: error.response.data.message,
            code: error.response.status
          })
        })
    })
  },

  update_info(params) {
    let _url = this.get_url("update_info")
    return new Promise((resolve, reject) => {
      account_axios
        .post(_url, params)
        .then(response => {
          resolve({
            data: response.data.data,
            message: response.data.message,
            code: response.status
          })
        })
        .catch(error => {
          console.error(error)
          reject({
            data: error.response.data.data,
            message: error.response.data.message,
            code: error.response.status
          })
        })
    })
  },

  reset_pwd(contact_info) {
    let params = { contact_info: contact_info }
    let _url = this.get_url("reset_pwd")
    return new Promise((resolve, reject) => {
      account_axios
        .post(_url, params)
        .then(response => {
            resolve({
              data: response.data.data,
              message: response.data.message,
              code: response.status
            })
        })
        .catch(error => {
          reject({
            data: error.response.data.data,
            message: error.response.data.message,
            code: error.response.status
          })
        })
    })
  },

  register(account_info) {
    let _url = this.get_url("register")
    return new Promise((resolve, reject) => {
      account_axios
        .post(_url, account_info)
        .then(response => {
          resolve({
            data: response.data.data,
            message: response.data.message,
            code: response.status
          })
        })
        .catch(error => {
          console.error(error)
          reject({
            data: error.response.data.data,
            message: error.response.data.message,
            code: error.response.status
          })
        })
    })
  },

  send_verification(username) {
    let _url = this.get_url("send_verification")
    return new Promise((resolve, reject) => {
      account_axios
        .post(_url, { username: username})
        .then(response => {
          resolve({
            data: response.data.data,
            message: response.data.message,
            code: response.status
          })
        })
        .catch(error => {
          console.error(error)
          reject({
            data: error.response.data.data,
            message: error.response.data.message,
            code: error.response.status
          })
        })
    })
  },

  verify_verification(username, code) {
    let _url = this.get_url("verify_verification")
    return new Promise((resolve, reject) => {
      account_axios
        .post(_url, {
          username: username,
          code: code
        })
        .then(response => {
          resolve({
            data: response.data.data,
            message: response.data.message,
            code: response.status
          })
        })
        .catch(error => {
          console.error(error)
          reject({
            data: error.response.data.data,
            message: error.response.data.message,
            code: error.response.status
          })
        })
    })
  }
}
