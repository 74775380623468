<template>
  <div
    v-show="visible"
    class="mask z-modal"
    @click.self="mask_closable ? _toggle(false) : null"
  >

    <div class="card--float w-11/12 md:w-1/2 lg:w-1/3 top-8 relative p-4 my-0 mx-auto" :class="card_style">
      <header class=" text-center mb-4">
        <slot name="header">
          <h3 class=" text-lg font-bold tracking-wider">
            {{ title }}
          </h3>
        </slot>
      </header>

      <i
        v-show="closable"
        class=" icon-symbol_close text-xl font-bold text-black-400 absolute top-4 right-4 cursor-pointer"
        @click="_toggle(false)"
      >
      </i>

      <main>
        <div class="modal-content text-left my-2 overflow-y-scroll scrollbar-hide">
          <slot>
            <div v-if="html_content" v-html="html_content"></div>
            <div v-else class=" text-center text-lg top-2 bottom-4 tracking-wider">
              {{ content }}
            </div>
          </slot>
        </div>

        <slot name="footer">
          <div class=" w-full flex flex-wrap pt-2">
            <button
              v-if="cancel_btn_required"
              class="btn_cancel flex-1 mx-2"
              @click="_cancel"
            >
              {{ cancel_text }}
            </button>
            <slot name="ok_btn">
              <button
                class="btn_primary flex-1 mx-2"
                :disabled="loading || disabled"
                :loading="loading"
                @click="_ok"
              >
                <span>
                  {{ ok_text }}
                </span>
                <div></div>
              </button>
            </slot>
          </div>
        </slot>
      </main>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Modal',

  props: {
    // 顯示/隱藏modal，外層用 :visible.sync 控制
    visible: {
      type: Boolean,
      default: false
    },

    // 標題
    title: {
      type: String,
      default: "標題"
    },

    // 內容
    content: {
      type: String,
      default: "內容"
    },
    // html格式的內容
    html_content: {
      type: String,
      default: null
    },
    // P.S. 傳入內容的優先度：slot > html_content > content

    // 確認按鈕的文字
    ok_text: {
      type: String,
      default: "確定"
    },

    // 取消按鈕的文字
    cancel_text: {
      type: String,
      default: "取消"
    },

    // 是否需要取消按鈕
    cancel_btn_required: {
      type: Boolean,
      default: false
    },

    // 確認按鈕的狀態：disabled and loading，若有使用到則需由外層手動關閉modal
    // 若不希望點選ok直接關閉，也可以將loading設為false，由外部關閉
    loading: {
      type: Boolean,
      default: null
    },

    // 確認按鈕的狀態：disabled，用在content中有必填欄位的情況
    disabled: {
      type: Boolean,
      default: false
    },

    // 是否有右上角關閉按鈕
    closable: {
      type: Boolean,
      default: false
    },

    // 是否能點選周圍遮罩關閉
    mask_closable: {
      type: Boolean,
      default: false
    },

    // 是否能按esc關閉
    esc_to_close: {
      type: Boolean,
      default: false
    },

    // 是否能按enter確定
    enter_to_ok: {
      type: Boolean,
      default: false
    },

    // 自訂card的style class
    card_style: {
      type: String,
      default: null
    }
  },

  methods: {
    _cancel() {
      this.$emit('confirm', false)
      this._toggle(false)
    },

    _ok() {
      this.$emit('confirm', true)
      if (this.loading === null) { // 外層沒使用到loading，自動關閉modal
        this._toggle(false)
      }
    },

    _toggle(visible) {
      this.$emit('update:visible', visible)
    }
  },

  created() {
    // esc 關閉
    if (this.esc_to_close) {
      document.addEventListener("keyup", (e) => {
        if (e.key === "Escape" && this.visible) {
          this._toggle(false)
        }
      })
    }
    // enter 確定
    if (this.enter_to_ok) {
      document.addEventListener("keyup", (e) => {
        if (e.key === "Enter" && this.visible) {
          this._ok()
        }
      })
    }
  }
}
</script>

<style scoped>
.mask {
  @apply w-full h-full fixed inset-0 z-modal bg-black bg-opacity-30;
}

.card--float {
  @apply border border-gray-400 rounded-2xl bg-white;
  @apply shadow;
}

.modal-content {
  max-height: calc(100vh - 320px);  /* 減去header+footer以及其margin，還有ios手機可能長出的footer */
}
</style>>
