/**
 * axios
 * 1. 對應該 source 的 axios 設定，例如利用攔截器更改回傳的資料格式（response_wrapper）、統一例外的處理方法
 * 2. 封裝 http 方法
 */

import axios from 'axios'

const account_axios = axios.create({
  baseURL: process.env.VUE_APP_SERVER_HOST
})

account_axios.interceptors.response.use(res => {
  return res
})
account_axios.defaults.headers.get['Accept'] = 'application/json'
account_axios.defaults.headers.post['Content-Type'] = 'application/json'
account_axios.defaults.withCredentials = true
account_axios.defaults.xsrfCookieName = 'csrftoken'
account_axios.defaults.xsrfHeaderName = 'X-CSRFToken'
account_axios.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest'

function response_wrapper(response) {
  return {
    'data': response.data.data,
    'message': response.data.message,
    'code': response.status
  }
}

// request 攔截器
account_axios.interceptors.request.use((config) => {
    return config;
  }, (error) => {
    return Promise.reject(error);
  }
)

// response 攔截器
account_axios.interceptors.response.use((response) => {
    return response_wrapper(response)
  }, (error) => {
    console.error(error)

    if (error.response) {
      console.warn(response_wrapper(error.response))
    }

    if (!window.navigator.onLine) {
      alert("網路出了點問題，請重新連線後重整網頁");
      return;
    }

    return Promise.reject(response_wrapper(error.response));
  }
)

// 封裝 http 方法
export const req = (method, url, data=null) => {
  method = method.toLowerCase();
  switch (method) {
    case "post":
      return account_axios.post(url, data);
    case "get":
      return account_axios.get(url, { params: data });
    case "delete":
      return account_axios.delete(url, { data: data });
    case "put":
      return account_axios.put(url, data);
    case "patch":
      return account_axios.patch(url, data);
    default:
      console.log(`未知的 http method: ${method}`);
      return false;
  }
}