!function() {
    'use strict'

    if (!Array.deepClone) {
        Array.deepClone = function(obj) {
            let _result = []
            for (let idx = 0; idx < obj.length; idx++) {
            let _clonedValue = obj[idx]
            if (Array.isArray(_clonedValue)) {
                _clonedValue = Array.deepClone(obj[idx])
            } else if (_clonedValue.constructor === Object) {
                _clonedValue = Object.deepAssign({}, obj[idx])
            }
            _result.push(_clonedValue)
            }
            return _result
        }
    }


    if (!Object.isDist) {
        Object.isDist = function(obj) {
            if (obj) {
                return obj.constructor === Object
            }
            return false
        }
    }


    if (!Object.deepAssign) {

        Object.deepAssign = function () {
            if (arguments.length == 0) {
                return
            }
            if (arguments.length == 1) {
                if (Array.isArray(arguments[0])) {
                    return Array.deepClone(arguments[0])
                }
                return arguments[0]
            }

            let target = arguments[0]
            let source = arguments[1]

            for (let propName in source) {
                if (!source.hasOwnProperty(propName)) { continue }
                let clonedValue = source[propName]
                if (Array.isArray(clonedValue)) {
                    clonedValue = Array.deepClone(source[propName])
                } else if (Object.isDist(clonedValue)) {
                    let _target = target[propName]
                    if (_target && Object.isDist(_target)) {
                        clonedValue = Object.deepAssign(_target, source[propName])
                    }
                    else {
                        clonedValue = Object.deepAssign({}, source[propName])
                    }
                }
                target[propName] = clonedValue
            }

            for (let i=2; i<arguments.length; i++) {
                target = Object.deepAssign(target, arguments[i])
            }

            return target
        }
    }


    window.css_unit_join = function(value, unit) {
        // vale is int or float or string
        // unit is string or undefined
        // default unit is "px"
        try {
            value = parseFloat(value)
            if (!isNaN(value)) {
                if (!unit) { unit = "px" }
                return value.toString() + unit
            }
        }
        catch(e) {}
        return value
    }

    window.css_unit_split = function(size_str) {
        // return a pair of list as [value, unit]
        try {
            let rs = size_str.match(/(\d+)?([\w%]*)?/i)
            if (rs.length >= 2) {
                return [ parseFloat(rs[1]), rs[2] ]
            }
        }
        catch(e) {
            console.error(e)
        }
        return [size_str, ""]
    }
}();


export class CBCSS {
    static Unit(unit) {
        if (unit) {
            CBCSS.prototype.unit = unit
        }
        return CBCSS.prototype.unit
    }

    static Parse(str) {
        let rs = str.match(/(\d+)?([\w%]*)?/i)
        if (rs.length >= 2) {
            let value = parseFloat(rs[1])
            let unit = rs[2]
            return new CBCSS(value, unit)
        }
        return new CBCSS()
    }

    constructor(value, unit) {
        value = parseFloat(value)
        if (isNaN(value)) {
            this.value = NaN
        }
        else {
            this.value = value
        }
        if (unit) {
            this.unit = unit
        }
        else {
            this.unit = CBCSS.prototype.unit
        }
    }

    set_unit(unit) {
        this.unit = unit
    }

    get_unit() {
        return this.unit
    }

    toString(value) {
        // vale is int or float or string
        // unit is string or undefined
        // default unit is "px"
        try {
            value = parseFloat(value)
            if (isNaN(value)) {
                value = this.value
            }
            else {
                this.value = value
            }
        }
        catch(e) {
            value = this.value
        }
        return value.toString() + this.unit
    }

    parse(str) {
        return CBCSS.Parse(str)
    }
}
CBCSS.prototype.unit = 'px'
