<template>
  <div class="relative w-full h-full overflow-scroll">
    <div class="absolute flex justify-between flex-wrap w-full z-on_map">
      <div class="flex items-center gap-2 m-4">
        <span class="text-lg font-bold text-primary-heavy">污染源</span>
        <cb-select
          v-model="selected_pollutant"
          class="w-32"
        >
          <option value="PM2.5">PM2.5</option>
          <option value="O3_8hr">O3_8hr</option>
          <option value="O3">O3</option>
          <option value="PM10">PM10</option>
          <option value="SO2">SO2</option>
          <option value="NO2">NO2</option>
          <option value="AQI" selected>AQI</option>
  <!--  留個記錄，如何在 option 裡使用下標，但還不能處理英文 -->
  <!--        <option value="PM10">PM&#x2081;&#x2080;</option>-->
  <!--        <option value="O3">O&#x2083;</option>-->
  <!--        <option value="O3_8hr">O&#x2083;_&#x2088;HR</option>-->
  <!--        <option value="PM2.5">PM&#x2082;.&#x2085;</option>-->
  <!--        <option value="AQI" selected>AQI</option>-->
        </cb-select>
      </div>
      <button class="m-4 btn_primary--line" @click="download_map_img">下載</button>
    </div>
    <div
      id="map"
      class="w-full h-full"
    >
    </div>
  </div>
</template>
<script>

import { MainVState } from '@/state/main'
import leafletImage from 'leaflet-image/leaflet-image.js'

export default {
  name: 'Map',
  components: {

  },
  data() {
    return {
      selected_pollutant: 'AQI',

      area_layers: [],
      base_geojson_feature: require('@/assets/taiwan_county_border.json'),
      geojson_feature_collection: [{
          name: '北部',
          feature: require('@/assets/north.json')
        },
        {
          name: '竹苗',
          feature: require('@/assets/hsinchu_miaoli.json')
        },
        {
          name: '中部',
          feature: require('@/assets/middle.json')
        },
        {
          name: '雲嘉南',
          feature: require('@/assets/yunlin_chiayi_tainan.json')
        },
        {
          name: '高屏',
          feature: require('@/assets/kaohsiung_pingtung.json')
        },
        {
          name: '宜蘭',
          feature: require('@/assets/ilan.json')
        },
        {
          name: '花東',
          feature: require('@/assets/hualien_taitung.json')
        },
        {
          name: '馬祖',
          feature: require('@/assets/matsu.json')
        },
        {
          name: '金門',
          feature: require('@/assets/kinmen.json')
        },
        {
          name: '澎湖',
          feature: require('@/assets/penghu.json')
        },
      ]
    }
  },

  computed: {
    editing_pollutants_data() {
      return MainVState.editing_pollutants_data
    },

    editing_aqi_data() {
      return MainVState.editing_aqi_data
    },

    fcst_datetime_selected() {
      return MainVState.fcst_datetime_selected
    }
  },

  watch: {
    editing_pollutants_data: {
      handler() {
        this.refresh_map_color()
      },
      deep: true
    },

    editing_aqi_data: {
      handler() {
        this.refresh_map_color()
      },
      deep: true
    },

    fcst_datetime_selected: {
      handler() {
        this.refresh_map_color()
      }
    },

    selected_pollutant: {
      handler() {
        this.refresh_map_color()
      }
    },
  },

  methods: {
    refresh_map_color() {
      this.area_layers.forEach(item => {
        item.layer.setStyle(this.get_area_layer_style(item.name))
      })
    },

    get_area_layer_style(area) {
      let value = null
      if (this.selected_pollutant === 'AQI') {
        value = MainVState.get_editing_aqi_data(area).value
      } else {
        value = MainVState.get_editing_pollutant_data(area, this.selected_pollutant)
      }

      return {
        fillColor: MainVState.get_color_mapping(value),
        weight: 3,
        opacity: 0.9,
        color: 'gray',
        fillOpacity: 0.8
      }
    },

    download_map_img() {
      let map = this.map
      let self = this

      // locate map to default position
      map.setView([23.76, 120.78], 8)
      setTimeout(() => {
        leafletImage(map, function(err, canvas) {
          let a = document.createElement("a")
          a.href = canvas.toDataURL()
          a.download = `${self.fcst_datetime_selected.toLST().strftime('%Y%m%d')}.png`
          a.click()
          a.remove()
        })
      }, 300)
    }
  },

  mounted() {
    this.$nextTick(() => {
      this.map = L.map('map', {
        renderer: L.canvas(),
        minZoom: 8,
        maxZoom: 20,
        zoomControl: false
      }).setView([23.76, 120.78], 8)

      L.geoJSON(this.base_geojson_feature, {
        style: () => {
          return {
            color: 'black',
            weight: 1,
            opacity: 1,
            fillOpacity: 0
          }
        }
      }).addTo(this.map)

      this.geojson_feature_collection.forEach((e) => {
        const _layer = {
          name: e.name,
          layer: L.geoJSON(e.feature, {
            style: () => {
              return this.get_area_layer_style(e.name)
            }
          }).addTo(this.map)
        }
        this.area_layers.push(_layer)
      })
    })
  }
}
</script>
