import Vue from 'vue'

/** register Modal */
import CbModal from './Modal.vue'
Vue.component('cb-modal', CbModal)
import CbSelect from './Select.vue'
Vue.component('cb-select', CbSelect)

/** register Dialog */
import CbDialog from './Dialog.vue'
const DialogComponent = Vue.extend(CbDialog)

Vue.prototype.$cb_confirm = (options) => {
  const dialog = new DialogComponent().$mount()
  let _options = options instanceof Object ? options : { content: options }
  Object.assign(dialog, {
    dialog_type: 'confirm',
    title: '確認'
  }, _options)
  return dialog.launch()
}

Vue.prototype.$cb_alert = (options) => {
  const dialog = new DialogComponent().$mount()
  let _options = options instanceof Object ? options : { content: options }
  Object.assign(dialog, {
    dialog_type: 'alert',
    title: '通知'
  }, _options)
  return dialog.launch()
}
