import Vue from 'vue'
import '@/libs/cb-static/cb.util'
import '@/libs/cb-static/sprintf.min'
import '@/libs/cb-static/cb.date'
import {FcstMultiModelsRepo} from '@/repositories/fcst_multi_models'
import {ObsRepo} from '@/repositories/obs'
import {FcstPublishedRepo} from "@/repositories/fcst_published";
import {FcstEditedRepo} from "@/repositories/fcst_edited";

const SEVERITY_COLOR = {
  0: 'rgb(212, 212, 216)',
  1: 'rgb(0, 151, 101)',
  2: 'rgb(255, 251, 38)',
  3: 'rgb(255, 151, 52)',
  4: 'rgb(202, 0, 52)',
  5: 'rgb(103, 0, 153)',
  6: 'rgb(126, 1, 35)'
}

const MainVState = new Vue({
  data() {
    return {
      is_loading: false,
      area_list: [
        '北部',
        '竹苗',
        '中部',
        '雲嘉南',
        '高屏',
        '宜蘭',
        '花東',
        '馬祖',
        '金門',
        '澎湖'
      ],

      pollutant_list: [
        'PM2.5',
        'O3_8hr',
        'O3',
        'PM10',
        'SO2',
        'NO2'
      ],  // 編輯介面上由左至右的順序

      obs_data: {},             // 觀測資料，由後端取得
      fcst_edited_data: {},     // 預報員事先編輯的暫存資料，由後端取得
      fcst_published_data: {},  // V3 對外發布的資料，由後端取得

      default_pollutants_data: {},  // 污染物資料初始值(某空品區某預報時間）
      editing_pollutants_data: [],  // 編輯界面上的污染物資料
      editing_aqi_data: [],         // 編輯界面上的主要/次要 AQI 資料

      system_datetime: new Date(),
      release_time: new Date(),

      selected_pollutant_list: [],
      selected_area_list: [],
      editing_type: 'AQI',  // 'AQI' or 'concentration'

      fcst_datetime_list: [],
      fcst_datetime_selected: null,
      selected_models: [],
      selected_algorithm: 'avg',
      operation_steps: [], // 紀錄預報員操作的狀態
      current_idx: 0 // 目前所在的步驟位置
    }
  },

  methods: {
    set_default_pollutant_data() {
      this.pollutant_list.forEach(pollutant => {
        this.default_pollutants_data[pollutant] = {
          'concentration': {
            'value': null,
          },
          'AQI': {
            'value': null
          }
        }
      })
    },

    init_all_editing_data() {
      // init editing_aqi_data and edit_pollutants_data
      this.fcst_datetime_list.forEach(fcst_datetime => {
        this.init_editing_data(fcst_datetime)
      })
    },

    init_editing_data(fcst_datetime) {
      this.editing_aqi_data = this.editing_aqi_data.filter(
        e => ! e.fcst_time.eq(fcst_datetime))

      this.editing_pollutants_data = this.editing_pollutants_data.filter(
        e => ! e.fcst_time.eq(fcst_datetime))

      this.area_list.forEach(area => {
        this.editing_aqi_data.push({
          fcst_time: fcst_datetime,
          area: area,
          pollutants: {
            'Primary_AQI': {
              'AQI': {
                'value': null
              }
            },
            'Secondary_AQI': {
              'AQI': {
                'value': null
              }
            }
          }
        })

        this.editing_pollutants_data.push({
          fcst_time: fcst_datetime,
          area: area,
          pollutants: Object.deepAssign({}, this.default_pollutants_data)
        })
      })
    },

    set_fcst_datetime_selected(datetime_selected_shift) {
      this.fcst_datetime_selected = this.fcst_datetime_list[datetime_selected_shift]
    },

    set_release_time(){
      let system_hours = this.system_datetime.toLST().getHours()
      let system_minutes = this.system_datetime.toLST().getMinutes()
      let publish_time_list = [[10, 30], [16, 30], [22, 0]] // [hour, minute]

      let release_hours = null
      let release_minutes = null

      function today_seconds(hours, minutes, seconds=0) {
        return hours*60*60 + minutes*60 + seconds
      }

      let now_seconds = today_seconds(system_hours, system_minutes)
      for (let publish_time of publish_time_list) {
        let publish_second = today_seconds(publish_time[0], publish_time[1])
        if (now_seconds < publish_second) {
          release_hours = publish_time[0]
          release_minutes = publish_time[1]
          break
        }
      }

      if (release_hours == null) {
        release_hours = 24 + publish_time_list[0][0]
        release_minutes = publish_time_list[0][1]
      }

      this.release_time = new Date(this.system_datetime.toLST().setHours(release_hours))
      this.release_time = new Date(this.release_time.setMinutes(release_minutes))
    },

    set_fcst_datetime_list() {
      this.fcst_datetime_list = []
      let lst_release_time = this.release_time.toLST()
      let first_day = lst_release_time.zero_hour()
      // 早上 10:30 那一報會出現今天、明天、後天、大後天 (4報）
      let fcst_count = 4
      // 下午之後的預報只明天、後天、大後天
      if (lst_release_time.getHours() == 16) {
        first_day = first_day.addDays(1)
        fcst_count = 3
      }
      // 晚上報要明天、後天、大後天、大大後天
      else if (lst_release_time.getHours() == 22) {
        first_day = first_day.addDays(1)
      }

      for (let shift=0; shift < fcst_count; shift++) {
        this.fcst_datetime_list.push(first_day.addDays(shift))
      }

      this.fcst_datetime_selected = this.fcst_datetime_list[0]
    },

    get_model_info() {
      return new Promise((resolve, reject) => {
        FcstMultiModelsRepo.model.get()
          .then((payload) => {
            let data_list = this.collate_data(payload.data)
            resolve(data_list)
          })
          .catch((payload) => {
            reject(payload)
          })
      })
    },

    get_obs_info() {
      return new Promise((resolve, reject) => {
        ObsRepo.get()
          .then((payload) => {
            let data_list = this.collate_data(payload.data)
            resolve(data_list)
          })
          .catch((payload) => {
            reject(payload)
          })
      })
    },

    get_ensemble(params) {
      let validated = true
      let message = ''
      let models = this._get_selected_models(params.selected_models)
      if (Object.entries(models).length === 0) {
        message += '沒有選擇任何參考資料\n'
        validated = false
      }
      if (this.selected_area_list.length === 0) {
        message += '沒有選擇任何空品區\n'
        validated = false
      }
      if (this.selected_pollutant_list.length === 0) {
        message += '沒有選擇任何污染物\n'
        validated = false
      }

      if (! validated) {
        this.$cb_alert(message)
        return
      }
      this.is_loading = true
      FcstMultiModelsRepo.ensemble.post({
        init_time: this.release_time.isostrz(),
        fcst_time: this.fcst_datetime_selected.isostrz(),
        areas: this.selected_area_list,
        pollutants: this.selected_pollutant_list,
        algorithm: params.algorithm,
        multi_models: models,
      })
        .then((payload) => {
          let self = this
          let selected_editing_pollutants_data = this.editing_pollutants_data.filter(
            i => self.selected_area_list.includes(i.area) &&
            i.fcst_time.eq(this.fcst_datetime_selected))
          for (let info of selected_editing_pollutants_data) {
            let selected_area_ensemble_data = payload.data.find(i => i.area === info.area)
            for (let pollutant of self.selected_pollutant_list) {
              self.$set(info['pollutants'], pollutant, selected_area_ensemble_data['pollutants'][pollutant])
            }
          }
          this.is_loading = false
        })
        .catch((payload) => {
          console.log('error with get ensemble data', payload)
          this.is_loading = false
        })
    },

    _get_selected_models(selected_models) {
      let models = {}
      let selected_models_name = [...new Set(selected_models.map(i => i.name))]
      for (let model_name of selected_models_name) {
        let selected_model_info = selected_models.filter(i => i.name === model_name)
        models[model_name] = selected_model_info.map(i => i.init_time)
      }

      return models
    },

    get_severity_level(AQI) {
      if (AQI > 0 && AQI <= 50) {
        return 1
      }
      if (AQI > 50 && AQI <= 100) {
        return 2
      }
      if (AQI > 100 && AQI <= 150) {
        return 3
      }
      if (AQI > 150 && AQI <= 200) {
        return 4
      }
      if (AQI > 200 && AQI <= 300) {
        return 5
      }
      if (AQI > 300) {
        return 6
      }
      return 0
    },

    get_color_mapping(AQI) {
      return SEVERITY_COLOR[this.get_severity_level(AQI)]
    },

    set_editing_pollutant_data_by_fcst(fcst_datas) {
      this.init_all_editing_data()
      fcst_datas.forEach(fcst_data => {
        const area_editing = this.editing_pollutants_data.find(
          e => e.area === fcst_data.area &&
          e.fcst_time.eq(fcst_data.fcst_time))
        if (area_editing) {
          Object.deepAssign(area_editing['pollutants'], fcst_data['pollutants'])
        }
      })
    },

    set_editing_pollutant_data_by_fcsttime() {
      this.set_editing_pollutant_data_by_fcst(this.fcst_edited_data)
    },

    get_published_data() {
      FcstPublishedRepo.get()
        .then((payload) => {
          this.fcst_published_data = this.collate_data(payload.data)
          // 因晚上報預報日比下午報多出一日，所以拿下午報第三日預報當作晚上報第四日預報預設值
          let lst_release_time = this.release_time.toLST()
          if (lst_release_time.getHours() == 22) {
            let first_day = lst_release_time.zero_hour()
            let lst_day = first_day.addDays(3)
            this.fcst_published_data.forEach(fcst_data => {
              if (fcst_data.hasOwnProperty('fcst_time')) {
                if (fcst_data.fcst_time.getTime() == lst_day.getTime()) {
                  let lst_data = Object.deepAssign({}, fcst_data)
                  lst_data.fcst_time = lst_day.addDays(1)
                  this.fcst_published_data.push(lst_data)
                }
              }
            })
          }
          this.set_editing_pollutant_data_by_fcst(this.fcst_published_data)
        })
        .catch((payload) => {
          console.log('error occur with get published data', payload)
        })
    },

    get_fcst_edited_data() {
      FcstEditedRepo.get()
        .then((payload) => {
          this.fcst_edited_data = this.collate_data(payload.data)
        })
        .catch((payload) => {
          console.log('error with get edited data', payload)
        })
    },

    collate_data(raw_data) {
      // convert datetime string to Date object
      raw_data.forEach(item => {
        ['fcst_time', 'init_time', 'obs_time'].forEach(varname => {
          if (item.hasOwnProperty(varname)) {
            if (Array.isArray(item[varname])) {
              item[varname] = item[varname].map(x => new Date(x))
            }
            else {
              item[varname] = new Date(item[varname])
            }
          }
        })
      })

      return raw_data
    },

    save_editing_data() {
      let self = this
      let fcst_edited_data = this.editing_pollutants_data.map(function(obj) {
        let result = Object.deepAssign({}, obj)
        result['init_time'] = self.release_time.isostrz()
        result['fcst_time'] = obj.fcst_time.isostrz()
        return result
      })

      // save forecast data to backend
      FcstEditedRepo.post(fcst_edited_data)
        .then((payload) => {
          this.$cb_alert('儲存成功')
          console.log('post_fcst_edited_data', payload.data)
        })
        .catch((payload) => {
          console.log('error with post edited data', payload)
        })
    },

    set_selected_pollutant_list(list) {
      this.selected_pollutant_list = list
    },

    set_selected_area_list(list) {
      this.selected_area_list = list
    },

    set_editing_pollutant_data(area, pollutant, data_type, value) {
      const target_area = this.editing_pollutants_data.find(
        e => e.area === area && e.fcst_time.eq(this.fcst_datetime_selected))
      if (target_area) {
        let target_pollutant = target_area['pollutants'][pollutant]
        if (target_pollutant) {
            target_pollutant[data_type]['value'] = Number(value)
        }
      }
    },

    set_editing_aqi_data(area, compute_aqi_type, value, pollutant) {
      const target_area = this.editing_aqi_data.find(
        e => e.area === area && e.fcst_time.eq(this.fcst_datetime_selected))
      if (target_area) {
        let target_pollutant = target_area['pollutants'][compute_aqi_type]
        if (target_pollutant) {
          this.$set(target_pollutant['AQI'], 'value', value)
          this.$set(target_pollutant['AQI'], 'pollutant', pollutant)
        }
      }
    },

    get_editing_aqi_data(area, compute_aqi_type='Primary_AQI') {
      const target_area = this.editing_aqi_data.find(
        e => e.area === area && e.fcst_time.eq(this.fcst_datetime_selected))
      if (target_area) {
        let target_pollutant = target_area['pollutants'][compute_aqi_type]
        if (target_pollutant) {
          return target_pollutant['AQI']
        }
      }
      return null
    },

    get_editing_pollutant_data(area, pollutant, type=this.editing_type) {
      if (this.editing_pollutants_data === null) {
        return null
      }

      const target_area = this.editing_pollutants_data.find(
        e => e.area === area && e.fcst_time.eq(this.fcst_datetime_selected))
      if (target_area) {
        let target_pollutant = target_area['pollutants'][pollutant]
        if (target_pollutant) {
          return target_pollutant[type]['value']
        }
      }
      return null
    },

    compute_aqi_by_area(area) {
      const target_area = this.editing_pollutants_data.find(
        e => e.area === area && e.fcst_time.eq(this.fcst_datetime_selected))
      let sorted_arr = []
      if (target_area) {
        for (let pollutant in target_area['pollutants']) {
          if (target_area['pollutants'].hasOwnProperty(pollutant)) {
            sorted_arr.push({
              'pollutant': pollutant,
              'value': target_area['pollutants'][pollutant]['AQI']['value']
            })
          }
        }
        sorted_arr.sort((a, b) => b.value - a.value)
      }

      if (sorted_arr.length > 0) {
        this.set_editing_aqi_data(target_area.area, 'Primary_AQI', sorted_arr[0].value, sorted_arr[0].pollutant)
      }
      if (sorted_arr.length > 1) {
        this.set_editing_aqi_data(target_area.area, 'Secondary_AQI', sorted_arr[1].value, sorted_arr[1].pollutant)
      }
    },

    set_editing_type(which_editing_type) {
      this.editing_type = which_editing_type
    },

    set_selected_models(models) {
      this.selected_models = models
    },

    set_selected_algorithm(algorithm) {
      this.selected_algorithm = algorithm
    },

    set_operation_steps(val) {
      if (this.operation_steps.length > 3) {
        this.operation_steps.shift()
      }
      let editing_pollutants_data = Array.deepClone(val)
      this.operation_steps.push({
        // 需紀錄的狀態包括：污染物數值、模式、計算方法、選取污染物、選取空品區
        'editing_pollutants_data': editing_pollutants_data,
        'selected_area_list': this.selected_area_list,
        'selected_pollutant_list': this.selected_pollutant_list,
        'selected_models': this.selected_models,
        'selected_algorithm': this.selected_algorithm
      })
      this.current_idx = this.operation_steps.length
    },

    remove_operation_steps(idx) {
      this.operation_steps = Array.deepClone(this.operation_steps.slice(0, idx))
    },

    reduce_current_idx() {
      this.current_idx -= 1
    },

    add_current_idx() {
      this.current_idx += 1
    },

    set_selected_operation_step(step) {
      this.editing_pollutants_data = step.editing_pollutants_data
      this.selected_area_list = step.selected_area_list
      this.selected_pollutant_list = step.selected_pollutant_list
      this.selected_models = step.selected_models
      this.selected_algorithm = step.selected_algorithm
    }
  },

  watch: {
    editing_pollutants_data: {
      handler() {
        this.area_list.forEach(area => {
          this.compute_aqi_by_area(area)
        })
      },
      deep: true
    },

    fcst_datetime_selected: {
      handler() {
        this.area_list.forEach(area => {
          this.compute_aqi_by_area(area)
        })
      }
    }

  },

  created() {
    // init
    this.set_release_time()
    this.set_default_pollutant_data()
    this.set_fcst_datetime_list()
    this.init_all_editing_data()

    // pull data
    this.get_published_data()
    this.get_fcst_edited_data()
  }
})

export {
  MainVState
}
