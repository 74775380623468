<template>
  <div class="cb_account-container">

    <section class="cb_account-section">
      <div class="flex_box column_full margin_bottom--large container_cloumn">
        <slot name="logo"></slot>
      </div>
      <div class="flex_box container_input--base">
        <input
          type="text"
          class="column_full margin_bottom--medium input--base"
          :placeholder="username_text"
          v-model="username"
          @keyup.enter="focus_pwd"
        >
        <input
          type="password"
          class="column_full margin_bottom--medium input--base"
          placeholder="密碼"
          v-model="password"
          @keyup.enter="login"
        >
        <a
          v-if="register_required"
          class="column_full"
          @click="forgot_pwd_modal_visible = true"
        >忘記密碼?</a>
      </div>

      <hr class="separate_line">

      <div class="double_btn_group">
        <button
          v-if="register_required"
          class="btn_normal"
          @click="$router.push(register_path)"
        >註冊會員
        </button>
        <button
          type="button"
          class="status_btn btn_primary"
          :loading="is_logging_in"
          :disabled="is_logging_in"
          @click="login"
        >
          <span>登入</span>
          <div></div>
        </button>
      </div>
      <slot name="extra_login">
      </slot>
    </section>

    <cb-modal
      :visible.sync="forgot_pwd_modal_visible"
      title="忘記密碼"
      ok_text="送出"
      :loading="is_resetting_pwd"
      @confirm="reset_pwd"
      cancel_btn_required
    >
      <template v-slot:content>
        <div class="flex_box container_input--base">
          <label class="heading--h3--owner">
            <slot name="reset_password_description">
              請輸入電子郵件或手機號碼以重設密碼
            </slot>
          </label>
          <input
            type="text"
            class="column_full margin_top--small input--base"
            v-model="contact_info"
          >
        </div>
      </template>
    </cb-modal>
  </div>
</template>

<script>
import { UserVState } from '@/libs/cb-account/vstate'

export default {
  name: 'CBAccountLogin',

  props: {
    // 登入成功後，重導向的預設頁面
    homepage_path: {
      type: Object,
      default: () => { return {name: 'Home'} }
    },

    // 點擊「註冊會員」時，導向的註冊頁面
    register_path: {
      type: Object,
      default: () => { return {name: 'Register'} }
    },

    // 登入回傳403時，導向的手機驗證頁
    verify_sms_path: {
      type: Object,
      default: () => { return {name: 'VerifySMS'} }
    },

    // 帳號類別，帳號可能為手機或email或帳號
    username_type: {
      type: String,
      default: 'username' // username | mobile | email
    },

    // 是否需要註冊即重設密碼功能，true: 需要註冊及忘記密碼功能
    register_required: {
      type: Boolean,
      default: false
    }
  },

  data() {
    return {
      user_vstate: UserVState,

      is_logging_in: false,
      is_resetting_pwd: false,
      forgot_pwd_modal_visible: false,

      username: '',
      password: '',
      contact_info: '',
    }
  },

  computed: {
    username_text() {
      if (this.username_type === 'mobile') {
        return '帳號（手機號碼）'
      }
      else if (this.username_type === 'email') {
        return '帳號（電子信箱）'
      }
      else {
        return '帳號'
      }
    },
  },

  methods: {
    login() {
      this.is_logging_in = true
      this.user_vstate.login(this.username, this.password)
        .then(() => {
          this.$router.push(this.$route.query.redirect || this.homepage_path)
        })
        .catch(code => {
          if (code === 403) {
            alert('您尚未驗證通過，請重新驗證')
            if (this.username_type === 'mobile') {
              this.$router.push(this.verify_sms_path)
            }
          }
          else if (code === 401) {
            alert('帳號密碼錯誤')
          }
          else if (code === 500) {
            alert('系統內部發生錯誤')
          }
        }).finally(() => {
          this.is_logging_in = false
        })
    },

    focus_pwd() {
      document.getElementById("password").focus()
    },

    reset_pwd(modal_is_ok) {
      if (!modal_is_ok) { return }

      if (this.contact_info === "") {
        alert('請輸入電子信箱或手機號碼')
        return
      }

      this.is_resetting_pwd = true
      UserVState.reset_pwd(this.contact_info)
        .then(message => {
          alert(message)
          this.forgot_pwd_modal_visible = false
          this.contact_info = ""
        })
        .catch(status_code => {
          if (status_code === 400) {
            alert('請輸入電子信箱或手機號碼。')
          }
          if (status_code === 404) {
            alert('此信箱/手機並未註冊任何帳號。')
          }
          if (status_code === 500) {
            alert('發生不明錯誤，請聯絡客服人員。')
          }
        })
        .finally(() => {
          this.is_resetting_pwd = false
        })
    }
  }
}
</script>

<style lang="scss" scoped>
@import './index.scss';
</style>
