<template>
  <div class="flex h-full">
    <div class="flex border-r w-[460px] max-h-full overflow-y-scroll">
      <Aside/>
    </div>
    <div class="flex-1 grid_wrap">
      <div style="grid-column: 1 / span 2;">
        <Nav/>
      </div>
      <div class="overflow-scroll">
        <Editor/>
      </div>
      <Map/>
    </div>
    <div
      v-show="is_loading"
      class="mask"
    >
      <div class="center">
        <div class="w-20 h-20 loader_rotate"></div>
      </div>
    </div>
  </div>
</template>

<script>
import Nav from '@/components/main/Nav'
import Editor from '@/components/main/Editor'
import Aside from '@/components/main/Aside'
import Map from '@/components/main/Map'

import { MainVState } from '@/state/main'

export default {
  name: 'AQFE',

  components: {
    Nav,
    Aside,
    Editor,
    Map
  },

  computed: {
    is_loading() {
      return MainVState.is_loading
    }
  }
}
</script>

<style lang="scss" scoped>
.grid_wrap {
  @apply grid h-full;
  grid-template-columns: min-content auto;
  grid-template-rows: min-content auto;
}
</style>
