import Vue from 'vue'
import { AccountRepository } from './repository'


const UserVState =  new Vue({
  data() {
    return {
      id: undefined,
      username: undefined,
      first_name: undefined,
      mobile: undefined,
      email: undefined,

      user_permissions: null
    }
  },

  methods: {
    register(account_info) {
      return new Promise((resolve, reject) => {
        AccountRepository.register(account_info)
          .then(payload => {
            this.username = payload.data.username
            resolve()
          })
          .catch(payload => {
            reject(payload)
          })
      })
    },

    login(username, password) {
      return new Promise((resolve, reject) => {
        AccountRepository.login({username, password})
          .then(payload => {
            this.id = payload.data.pk
            this.username = payload.data.username
            this.get_user_permissions()
            resolve()
          })
          .catch(payload => {
            if (payload.code === 403) {
              // 登入會去檢查使用者是否通過驗證 SMS/email
              // 若驗證未通過會導到驗證頁面
              // 驗證頁面會需要 username 跟後端溝通
              this.username = payload.data.username
            }
            reject(payload.code)
          })
      })
    },

    verify(permission_code) {
      return new Promise((resolve, reject) => {
        AccountRepository.verify({
          permission_code: permission_code
        })
          .then(payload => {
            if (this.id !== payload.data.pk) {
              this.id = payload.data.pk
              this.username = payload.data.username
              this.get_user_permissions()
            }
            resolve()
          })
          .catch(payload => {
            reject(payload.code)
          })
      })
    },

    logout() {
      return new Promise((resolve, reject) => {
        AccountRepository.logout()
          .then(payload => {
            this.username = undefined
            resolve(payload)
          })
          .catch(payload => {
            reject(payload.code)
          })
      })
    },

    reset_pwd(contact_info) {
      return new Promise((resolve, reject) => {
        AccountRepository.reset_pwd(contact_info)
          .then(payload => { resolve(payload.message) })
          .catch(payload => { reject(payload.code) })
      })
    },

    change_pwd(data) {
      if (!data["username"]) {
        data["username"] = this.username
      }
      return new Promise((resolve, reject) => {
        AccountRepository.change_pwd(data)
          .then(payload => {
            resolve(payload)
          })
          .catch(payload => {
            reject(payload)
          })
      })
    },

    update_info(username_type) {
      let params = {
        username: this.username,
        first_name: this.first_name
      }
      if (username_type !== "mobile") {
        params.mobile = this.mobile
      }
      if (username_type !== "email") {
        params.email = this.email
      }
      return new Promise((resolve, reject) => {
        AccountRepository.update_info(params)
          .then(payload => {
            resolve()
          })
          .catch(payload => {
            reject(payload)
          })
      })
    },

    get_profile() {
      return new Promise((resolve, reject) => {
        AccountRepository.get_profile()
          .then(payload => {
            this.id = payload.data.pk
            this.username = payload.data.username
            this.first_name = payload.data.first_name
            this.mobile = payload.data.mobile ? payload.data.mobile : ""
            this.email = payload.data.email ? payload.data.email : ""
            resolve()
          })
          .catch(payload => {
            reject(payload.code)
          })
      })
    },

    send_verification() {
      return new Promise((resolve, reject) => {
        AccountRepository.send_verification(this.username)
          .then(payload => {
            resolve()
          })
          .catch(payload => {
            reject(payload.code)
          })
      })
    },

    verify_verification(code) {
      return new Promise((resolve, reject) => {
        AccountRepository.verify_verification(this.username, code)
          .then(payload => {
            resolve()
          })
          .catch(payload => {
            reject(payload.code)
          })
      })
    },

    get_user_permissions() {
      AccountRepository.user_permission.get()
        .then((payload) => {
          this.user_permissions = payload.data.map(perm => {
            return perm.codename
          })
        })
    },

    has_permission(perm_code) {
      if (this.user_permissions) {
        return this.user_permissions.includes(perm_code)
      }
    }
  }
})

export {
  UserVState
}
